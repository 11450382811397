import{ REACT_APP_API_BASE_URL } from 'global';

export const isMintproApp = () => {
  return window.mpIsApp === true;
}

export const isNinjaApp = () => {
  return !!window.ninjaIsApp;
}

export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const registerWebinars = (webinarKey, key) => {
  return new Promise((resolve, reject) => {
    let url = `${REACT_APP_API_BASE_URL}/api/lms/webinars/register/`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-PG-USER-ID': key
      },
      body: JSON.stringify({
        'key': webinarKey,
      })
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      });
  })
}

export const getYoutubeId = (url) => {
  const result = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  const videoIdWithParams = result[2];
  if (videoIdWithParams !== undefined) {
    const youtubeVideoId = videoIdWithParams.split(/[^0-9a-z_-]/i)[0];
    return youtubeVideoId;
  }
  return null;
}

export const getSearchHistory = (key) => {
  return new Promise((resolve, reject) => {
    fetch(`${REACT_APP_API_BASE_URL}/api/lms/search/history/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-PG-USER-ID': key
      }
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const createApesterEventData = (eventData, type, token, user_key, flashcardKey) => {
  let body;
  if (type === 'picked_answer') {
    body = {
      "user": user_key,
      "flashcard": flashcardKey,
      "type": type,
      "data": {
        "slideId": eventData.data.slideId,
        "slideName": eventData.data.slideTitle,
        "interactionIndex": eventData.data.interactionIndex,
        "answerId": eventData.data.answerId,
        "answerText": eventData.data.answerText,
      }
    }
  }
  if (type === 'quiz_score') {
    body = {
      "user": user_key,
      "flashcard": flashcardKey,
      "type": type,
      "data": {
        "correctAnswers": eventData.correctAnswers,
        "itemsCount": eventData.itemsCount,
        "score": eventData.score
      }
    }
  }
  return new Promise((resolve, reject) => {
    fetch(`${REACT_APP_API_BASE_URL}/api/lms/flashcards/user_flashcard_interaction/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-PG-USER-ID': token
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export const isContentCompleted = (videoDuration, playedSeconds, videoThreshold) => {
  // video threshold param in percantage (ex- 0.3 to show next button after completing 70% of video).
  let timeLimit = Math.floor(videoDuration * videoThreshold);
  if (videoDuration - playedSeconds <= timeLimit) {
    //set time finished true if playing video time is greater than 70% of total video.
    return true;
  }
  return false;
}

export const getChannelCategoriesToShow = (channelCategories) => {
  return channelCategories.map((channelCategory) => {
    return {
      id: channelCategory.key,
      value: channelCategory.name,
    };
  });
}

export const isChannelCourse = (params) => {
  return params.channelKey;
}

export const titleCase = (str) => {
  if (str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }
}

export const parseTime = (n) => {
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  let res = "";
  if (rhours !== 0) {
    res += rhours + "hr "
  }
  if (rminutes) {
    res += rminutes + "min"
  }
  return res;
}


export const format = (seconds) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = ('0' + date.getUTCSeconds()).slice(-2);
  if (hh) {
    return `${hh}:${('0' + mm).slice(-2)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

export const checkIPPB = (branch_key) => {
  if (branch_key === "ahNzfnR1cnRsZW1pbnQtMTQzOTEzchYLEglMTVNCcmFuY2gYgICA4Y3b2QgM" ||
    branch_key === "ahhqfm1pbnRwcm8tdW5pdmVyc2l0eS11YXRyFgsSCUxNU0JyYW5jaBiAgICAmoKICww") {
    return true;
  }
  return false;
}

export const isMobile = () => {
  if (window.innerWidth < 575) {
    return true;
  }
  return false;
}

export const arrangeModuleDataAccToIntroModuleFlag = modules => {

  const introModuleData = modules.filter((module, idx) => module.is_intro_module)
  const introModuleIndex = modules.indexOf(introModuleData[0]);
  if(introModuleIndex !== -1){
  modules.splice(introModuleIndex,1);
  modules.unshift(introModuleData[0]);
 }
  return { introPresentModule: introModuleIndex === -1 ? false : true, moduleData : modules };
}

export const isMintProBranch = (branchName) => {
  if(branchName === 'MintPro') return true;
  return false;
}

export const functiontofindIndexByKeyValue = (arraytosearch, key, valuetosearch) => {
  for (var i = 0; i < arraytosearch.length; i++) { 
    if (arraytosearch[i][key] === valuetosearch) {
    return i;
    }
  }
  return -1;
}


export const changeValueLabelPair = (data, valueData, labelData, pcCodeData, hideFromSecondaryCategory) => {
  let array = []
  for (let i = 0; i < data.length; i++) {
    array.push({
      value: `${data[i][valueData]}`,
      label: `${data[i][labelData]}`,
      pcCode: `${data[i][pcCodeData]}`,
      hideFromSecondaryCategory: `${data[i][hideFromSecondaryCategory]}`
    })
  }
  return array;
}

export const skillInfoData = skillData => {
  const { skill_info } = skillData
    let skillInfoData = {
        "primary_capability": null,
        "primary_skill": null,
        "primary_vertical": null,
        "primary_granular_skill": null,
    };

    if(skill_info) {
      const { capability, skill, skill_component, vertical }  = skill_info;
      skillInfoData = {
        "primary_capability": capability,
        "primary_skill": skill,
        "primary_vertical": vertical,
        "primary_granular_skill": skill_component,
      }
    }
    return skillInfoData;
}

export const isFileBasedContentType = content_type => {
  return ["Video", "Pdf", "Image"].some(type => type === content_type);
}

export const VIDEO_TYPE = [
  {
    label: "MP4",
    value: "mp4"
  }, {
    label : "YouTube",
    value: "youtube"
  }
]